import { APICore } from './apiCore';
import * as authHelper from '../../helpers/authHelper';
import { API_BASE_URL } from '../../constants/apiConstants';

const api = new APICore();

function login(params: any): any {
    const baseUrl = API_BASE_URL + 'api/Login/Validation';
    return api.create(`${baseUrl}`, params);
}

function getListOfMenus(params: any): any {
    if (!authHelper.IsNullOrUndefined(params.roleId)) params.roleId = 0;

    const baseUrl = API_BASE_URL + 'api/Menu/GetListOfMenusByRoleID';
    return api.get(`${baseUrl}`, {
        roleId: params.roleId,
        userId: params.userId,
        isAccessCaseDocuments: params.isAccessCaseDocuments,
        additionalServices: params.additionalServices,
    });
}

function getUserComponentList(params: any): any {
    const baseUrl = API_BASE_URL + 'api/Dashboard/GetUserComponentListByRoleId';
    return api.get(`${baseUrl}`, {
        roleId: params.user.roleId,
        isAccessCaseDocuments: params.user.isAccessCaseDocuments,
    });
}

function GetDashboardData(params: any): any {
    const baseUrl = API_BASE_URL + 'api/Dashboard/GetDashboardData';

    return api.get(`${baseUrl}`, { categoryCount: params.categoryCount, userId: params.userId });
}

function CreateUser(params: any): any {
    const baseUrl = API_BASE_URL + 'api/User/CreateUser';
    return api.create(`${baseUrl}`, { ...params });
}

function CreateTempUser(params: any): any {
    const baseUrl = API_BASE_URL + 'api/Registration/CreateTempUser';
    return api.create(`${baseUrl}`, { ...params });
}

function CreateCustomeEditEmailTemplate(params: any): any {
    const baseUrl = API_BASE_URL + 'api/EmailTemplate/SaveEmailTemplate';
    return api.create(`${baseUrl}`, { ...params });
}

function GetRoles(params: any): any {
    const baseUrl = API_BASE_URL + 'api/User/GetRoles';
    return api.get(`${baseUrl}`, { isDisplay: params.isDisplay });
}

function getDocumentByStorageId(params: any): any {
    const baseUrl = API_BASE_URL + 'api/Document/GetDownloadFile';
    return api.get(`${baseUrl}`, { storageId: params });
}

function GetUserDetails(params: any): any {
    const baseUrl = API_BASE_URL + 'api/User/GetUserDetails';
    return api.get(`${baseUrl}`, { userId: params });
}

function SendInvitations(params: any): any {
    const baseUrl = API_BASE_URL + 'api/EmailTemplate/SendInvitation';
    return api.create(`${baseUrl}`, JSON.stringify(params.SendInvitationDataAction));
}

export {
    login,
    GetRoles,
    CreateUser,
    CreateTempUser,
    GetUserDetails,
    getListOfMenus,
    SendInvitations,
    getUserComponentList,
    getDocumentByStorageId,
    GetDashboardData,
    CreateCustomeEditEmailTemplate,
};
