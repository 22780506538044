import Root from './Root';
import React, { Suspense } from 'react';
import FullLayout from '../layouts/Full';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import { useRoutes } from 'react-router-dom';
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import * as authHelper from '../helpers/authHelper';
import HorizontalLayout from '../layouts/Horizontal';
import * as layoutConstants from '../constants/layout';

const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const EcommerceDashboard = React.lazy(() => import('../pages/dashboard'));
const AddUser = React.lazy(() => import('../pages/apps/Ecommerce/AddUser'));
const ViewUser = React.lazy(() => import('../pages/apps/Ecommerce/ViewUser'));
const UserList = React.lazy(() => import('../pages/apps/Ecommerce/UserList'));
const SubmittersList = React.lazy(() => import('../pages/apps/Ecommerce/SubmittersList'));
const ApprovedDelegatesList = React.lazy(() => import('../pages/apps/Ecommerce/ApprovedDelegatesList'));
const DelegatesRequestingAuthorization = React.lazy(() =>
    import('../pages/apps/Ecommerce/DelegatesRequestingAuthorization')
);
const ChangePassword = React.lazy(() => import('../pages/apps/Ecommerce/ChangePassword'));
const EmailHistoryList = React.lazy(() => import('../pages/apps/Ecommerce/EmailHistoryList'));
const RecipientsList = React.lazy(() => import('../pages/apps/Ecommerce/RecipientsList'));
const SendInvitation = React.lazy(() => import('../pages/apps/Ecommerce/SendInvitation'));
const DocumentUpload = React.lazy(() => import('../pages/apps/Ecommerce/DocumentUpload'));
const LoginHelp = React.lazy(() => import('../pages/account/LoginHelp'));
const ForgetUserName = React.lazy(() => import('../pages/account/ForgetUserName'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/account/ResetPassword'));
const ConfirmAuthorization = React.lazy(() => import('../pages/account/ConfirmAuthorization'));
const AddTrusteeCategory = React.lazy(() => import('../pages/apps/Ecommerce/AddTrusteeCategory'));
const TrusteeCategoryList = React.lazy(() => import('../pages/apps/Ecommerce/TrusteeCategoryList'));
const ViewTrusteeCategory = React.lazy(() => import('../pages/apps/Ecommerce/ViewTrusteeCategory'));
const AddDocumentCategory = React.lazy(() => import('../pages/apps/Ecommerce/AddDocumentCategory'));
const AddNoticingCategory = React.lazy(() => import('../pages/apps/Ecommerce/AddNoticingCategory'));
const DocumentCategoryList = React.lazy(() => import('../pages/apps/Ecommerce/DocumentCategoryList'));
const NoticingCategoryList = React.lazy(() => import('../pages/apps/Ecommerce/NoticingCategoryList'));
const ViewDocumentCategory = React.lazy(() => import('../pages/apps/Ecommerce/ViewDocumentCategory'));
const ViewNoticingCategory = React.lazy(() => import('../pages/apps/Ecommerce/ViewNoticingCategory'));
const ViewDocumentUploadHistory = React.lazy(() => import('../pages/apps/Ecommerce/ViewDocumentUploadHistory'));
const Maintenance = React.lazy(() => import('../pages/apps/Ecommerce/Maintenance'));

const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const UnAuthorizedAccess = React.lazy(() => import('../pages/error/UnAuthorizedAccess'));

const DocumentsHistories = React.lazy(() => import('../pages/apps/Ecommerce/DocumentsHistories'));
const TrackingHistory = React.lazy(() => import('../pages/apps/Ecommerce/TrackingHistory'));
const DocumentHistories = React.lazy(() => import('../pages/apps/Ecommerce/DocumentHistory'));
const EmailTemplateList = React.lazy(() => import('../pages/apps/Ecommerce/EmailTemplateList'));
const ViewInvitationEmailTemplate = React.lazy(() => import('../pages/apps/Ecommerce/ViewInvitationEmailTemplate'));
const InvitationEmailTemplate = React.lazy(() => import('../pages/apps/Ecommerce/InvitationEmailTemplate'));
const RequestDelegateAuthorization = React.lazy(() => import('../pages/apps/Ecommerce/RequestDelegateAuthorization'));
const EnterAuthorizationKey = React.lazy(() => import('../pages/apps/Ecommerce/EnterAuthorizationKey'));
const EmailPreferences = React.lazy(() => import('../pages/apps/Ecommerce/EmailPreferences'));
const ContactUs = React.lazy(() => import('../pages/apps/Ecommerce/ContactUs'));
const MasterAuthorizationKey = React.lazy(() => import('../pages/apps/Ecommerce/MasterAuthorizationKey'));
const UserMigration = React.lazy(() => import('../pages/apps/Ecommerce/UserMigration'));
const UserMigrationEdit = React.lazy(() => import('../pages/apps/Ecommerce/UserMigrationEdit'));
const LegacyDocumentHistory = React.lazy(() => import('../pages/apps/Ecommerce/LegacyDocumentHistory'));
const UserRelationship = React.lazy(() => import('../pages/apps/Ecommerce/UserRelationship'));
const TrusteeDocumentUpload = React.lazy(() => import('../pages/apps/Ecommerce/TrusteeDocumentUpload'));
const CaseList = React.lazy(() => import('../pages/apps/Ecommerce/CaseList'));
const AddCase = React.lazy(() => import('../pages/apps/Ecommerce/AddCase'));
const PendingInvitations = React.lazy(() => import('../pages/apps/Ecommerce/PendingInvitations'));
const NoticingDocumentsHistory = React.lazy(() => import('../pages/apps/Ecommerce/NoticingDocumentsHistory'));
const SendNotification = React.lazy(() => import('../pages/apps/Ecommerce/SendNotification'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const ClearSession = () => {
    sessionStorage.setItem('userSearch', '');
    sessionStorage.setItem('userFilterState', '');
};

const LoadComponent = ({ component: Component, retainUserSearch: retainUserSearch }: LoadComponentProps) => {
    if (authHelper.IsNullOrUndefined(retainUserSearch)) {
        if (!retainUserSearch) ClearSession();
    } else ClearSession();

    return (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    );
};

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;

            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;

            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;

            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            path: '/CaseDocuments',
            element: <Layout />,
            children: [
                {
                    path: '/CaseDocuments',
                    children: [{ path: '', element: <LoadComponent component={DocumentsHistories} /> }],
                },
            ],
        },
        {
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'loginHelp', element: <LoadComponent component={LoginHelp} /> },
                        { path: 'forgetUserName', element: <LoadComponent component={ForgetUserName} /> },
                        { path: 'forgetPassword', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'resetPassword', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'confirmAuthorization', element: <LoadComponent component={ConfirmAuthorization} /> },
                    ],
                },

                { path: 'error-404', element: <LoadComponent component={ErrorPageNotFound} /> },
                { path: 'error-500', element: <LoadComponent component={ServerError} /> },
                { path: 'Maintenance', element: <LoadComponent component={Maintenance} /> },
                { path: 'UnAuthorizedAccess', element: <LoadComponent component={UnAuthorizedAccess} /> },
            ],
        },

        {
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [{ path: '', element: <LoadComponent component={EcommerceDashboard} /> }],
                },

                {
                    path: 'apps',
                    children: [
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'AddUser',
                                    element: <LoadComponent component={AddUser} retainUserSearch={true} />,
                                },

                                {
                                    path: 'ViewUser',
                                    element: <LoadComponent component={ViewUser} retainUserSearch={true} />,
                                },

                                {
                                    path: 'UserList',
                                    element: <LoadComponent component={UserList} retainUserSearch={true} />,
                                },

                                { path: 'ChangePassword', element: <LoadComponent component={ChangePassword} /> },

                                { path: 'RecipientsList', element: <LoadComponent component={RecipientsList} /> },

                                { path: 'SubmittersList', element: <LoadComponent component={SubmittersList} /> },

                                {
                                    path: 'ApprovedDelegatesList',
                                    element: <LoadComponent component={ApprovedDelegatesList} />,
                                },

                                {
                                    path: 'DelegatesRequestingAuthorization',
                                    element: <LoadComponent component={DelegatesRequestingAuthorization} />,
                                },

                                { path: 'EmailHistoryList', element: <LoadComponent component={EmailHistoryList} /> },

                                { path: 'Maintenance', element: <LoadComponent component={Maintenance} /> },

                                {
                                    path: 'DocumentCategoryList',
                                    element: <LoadComponent component={DocumentCategoryList} />,
                                },

                                {
                                    path: 'AddDocumentCategory',
                                    element: <LoadComponent component={AddDocumentCategory} />,
                                },

                                {
                                    path: 'ViewDocumentCategory',
                                    element: <LoadComponent component={ViewDocumentCategory} />,
                                },

                                {
                                    path: 'NoticingDocumentCategoryList',
                                    element: <LoadComponent component={NoticingCategoryList} />,
                                },

                                {
                                    path: 'AddNoticingCategory',
                                    element: <LoadComponent component={AddNoticingCategory} />,
                                },

                                {
                                    path: 'ViewNoticingCategory',
                                    element: <LoadComponent component={ViewNoticingCategory} />,
                                },

                                {
                                    path: 'TrusteeCategoryList',
                                    element: <LoadComponent component={TrusteeCategoryList} />,
                                },

                                {
                                    path: 'AddTrusteeCategory',
                                    element: <LoadComponent component={AddTrusteeCategory} />,
                                },

                                {
                                    path: 'ViewTrusteeCategory',
                                    element: <LoadComponent component={ViewTrusteeCategory} />,
                                },

                                {
                                    path: 'DocumentHistory',
                                    element: <LoadComponent component={DocumentHistories} />,
                                },

                                {
                                    path: 'CaseDocumentHistory',
                                    element: <LoadComponent component={DocumentsHistories} />,
                                },

                                {
                                    path: 'TrusteeDocumentTrackingHistory',
                                    element: <LoadComponent component={TrackingHistory} />,
                                },
                                {
                                    path: 'TrusteeDocumentHistory',
                                    element: <LoadComponent component={DocumentsHistories} />,
                                },

                                {
                                    path: 'CaseDocumentTrackingHistory',
                                    element: <LoadComponent component={TrackingHistory} />,
                                },

                                {
                                    path: 'ViewDocumentUploadHistory',
                                    element: <LoadComponent component={ViewDocumentUploadHistory} />,
                                },

                                { path: 'SendInvitation', element: <LoadComponent component={SendInvitation} /> },

                                { path: 'DocumentUpload', element: <LoadComponent component={DocumentUpload} /> },

                                { path: 'EmailTemplateList', element: <LoadComponent component={EmailTemplateList} /> },

                                {
                                    path: 'RequestDelegateAuthorization',
                                    element: <LoadComponent component={RequestDelegateAuthorization} />,
                                },

                                {
                                    path: 'EnterAuthorizationKey',
                                    element: <LoadComponent component={EnterAuthorizationKey} />,
                                },

                                {
                                    path: 'InvitationEmailTemplate',
                                    element: <LoadComponent component={InvitationEmailTemplate} />,
                                },

                                {
                                    path: 'ViewInvitationEmailTemplate',
                                    element: <LoadComponent component={ViewInvitationEmailTemplate} />,
                                },

                                {
                                    path: 'EmailPreferences',
                                    element: <LoadComponent component={EmailPreferences} />,
                                },

                                {
                                    path: 'ContactUs',
                                    element: <LoadComponent component={ContactUs} />,
                                },

                                {
                                    path: 'MasterAuthorizationKey',
                                    element: <LoadComponent component={MasterAuthorizationKey} />,
                                },

                                {
                                    path: 'UserMigration',
                                    element: <LoadComponent component={UserMigration} />,
                                },

                                {
                                    path: 'UserMigrationEdit',
                                    element: <LoadComponent component={UserMigrationEdit} />,
                                },

                                {
                                    path: 'LegacyDocumentHistory',
                                    element: <LoadComponent component={LegacyDocumentHistory} />,
                                },
                                {
                                    path: 'UserRelationship',
                                    element: <LoadComponent component={UserRelationship} retainUserSearch={true} />,
                                },
                                {
                                    path: 'UploadTrusteeDocument',
                                    element: <LoadComponent component={TrusteeDocumentUpload} />,
                                },
                                {
                                    path: 'CaseList',
                                    element: <LoadComponent component={CaseList} />,
                                },
                                {
                                    path: 'AddCase',
                                    element: <LoadComponent component={AddCase} />,
                                },
                                {
                                    path: 'PendingInvitations',
                                    element: <LoadComponent component={PendingInvitations} />,
                                },
                                {
                                    path: 'NoticingDocumentsHistory',
                                    element: <LoadComponent component={NoticingDocumentsHistory} />,
                                },
                                {
                                    path: 'SendNotification',
                                    element: <LoadComponent component={SendNotification} />,
                                },
                            ],
                        },
                    ],
                },

                {
                    path: 'pages',
                    children: [{ path: 'error-404-alt', element: <LoadComponent component={ErrorPageNotFoundAlt} /> }],
                },
            ],
        },
    ]);
};

export { AllRoutes };
