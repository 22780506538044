// @flow
import { dynamicMenuTypes } from './constants';

type dynamicMenuAction = { type: string, payload: {} | string };

// common success
export const dynamicMenuApiResponseSuccess = (actionType: string, data: any): dynamicMenuAction => ({
    type: dynamicMenuTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const dynamicMenuApiResponseError = (actionType: string, error: string): dynamicMenuAction => ({
    type: dynamicMenuTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getListOfMenuDisplay = (
    roleId: string,
    userId: string,
    isAccessCaseDocuments: boolean,
    additionalServices: string
) => ({
    type: dynamicMenuTypes.GET_LIST_OF_MENUS,
    payload: { roleId, userId, isAccessCaseDocuments, additionalServices },
});
