import { dynamicMenuTypes } from './constants';
import { getListOfMenus } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { dynamicMenuApiResponseSuccess, dynamicMenuApiResponseError } from './actions';

function* getListOfMenusSaga({
    payload,
}: {
    payload: { roleId: int, userId: int, isAccessCaseDocuments: boolean, additionalServices: string },
}) {
    try {
        const response = yield call(getListOfMenus, payload);
        yield put(dynamicMenuApiResponseSuccess(dynamicMenuTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(dynamicMenuApiResponseError(dynamicMenuTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchGetListOfMenuDisplay(): any {
    yield takeEvery(dynamicMenuTypes.GET_LIST_OF_MENUS, getListOfMenusSaga);
}

function* dynamicSaga(): any {
    yield all([fork(watchGetListOfMenuDisplay)]);
}

export default dynamicSaga;
